<template>
    <modal-eliminar ref="modalEliminar" :titulo="titulo" @eliminar="EliminarRegistro">
        <div class="row mx-0 m-3 text-center justify-center">
            <b class="mx-2">
                {{ datos.tiendas_count }}
            </b> {{$config.vendedor}}s están asociadas a este tipo y no puede eliminarse.
        </div>
        <div class="row mx-0 m-3 text-center justify-center">
            {{ mensaje }}
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 m-3">
                <div class="col">
                    <label class="ml-3">Seleccione el tipo de tienda a trasladar</label>
                    <ValidationProvider v-slot="{errors}" rules="required" name="categoria">
                        <el-select v-model="model.categoria" class="w-100">
                            <el-option
                            v-for="item in listado.filter(e=> e.id !== datos.id)"
                            :key="item.id"
                            :label="item.singular"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-0 m-3 text-center justify-center">
            ¿Desea aceptar la acción?
        </div>
    </modal-eliminar>
</template>

<script>
import TipoTienda from '~/services/configurar/admin/tipoTiendas'
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        listado: {
            type: Array,
            default: () => { return [] }
        },
        mensaje: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            model:{
                categoria:null
            },
            datos:{}
        }
    },
    methods: {
        toggle(data){
            if(data){
                this.datos = data
            }
            this.$refs.modalEliminar.toggle()
        },
        async EliminarRegistro(){
            try {
                const valid = this.$refs.validacion.validate()
                if(valid){
                    let datos ={
                        id:this.datos.id,
                        id_categoria: this.model.categoria
                    }
                    const {data} = await TipoTienda.DeleteRegistro(datos.id,datos.id_categoria)
                    this.notificacion('', data.mensaje, 'success')
                    this.$refs.modalEliminar.toggle()
                    this.$emit('listar')
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
